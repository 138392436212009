<template>
  <ribbon class="has-background-dark has-text-white">
    <div class="columns is-multiline">
      <div
        class="column is-10-tablet is-offset-1-tablet is-12-widescreen is-offset-0-widescreen"
      >
        <h2 class="subtitle is-2">{{ $report.timeframe }} Report</h2>
        <h1 class="title is-1">{{ $report.fqdn }}</h1>
      </div>
    </div>
  </ribbon>
</template>

<script>
export default {
  name: "SiteReportMonitoring",
  inject: ["$reportProvider"],
  computed: {
    $report() {
      return this.$reportProvider();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.ribbon {
  padding: calc(0.5vw + 4vh) calc(0.5vw + 2vh);
  @include widescreen() {
    .column {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
</style>
